import React from 'react';
import under from "../assets/under.mp4"
//import Videos2 from './Videos2'



const Videos  = () => { 
return (
<>
<div className="vid-container">

<video className="vid-play" autoPlay loop muted 
style={{
  
    width:"auto",
   

    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr 1fr",
    //gridTemplateRows: "auto"
}}
>
   
<source src= {under}
type="video/mp4"/>

</video> 


 {/* <p className="pexel" 
 style={{
     marginTop:"-2%",
     zIndex:"100",
     marginLeft:"80%",
     paddingBottom:"0px", 
     
 }}>
&#169; &nbsp;www.pexels.com
 </p>   */}
{/*        <Videos2>

</Videos2> */}

<figcaption className="pexel"> &#169; &nbsp;www.pexels.com</figcaption>
</div>
</>)

}

export default Videos
