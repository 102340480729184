import React from 'react'
import { useForm } from "react-hook-form";
import "./Contact.css"

export default function Contact(props) {
    // renderCount++;
    const { register } = useForm();
    return(
        <div className="container-contact">
          <h3 className="me">Contact Me</h3>  
    <form action="" className="formInfo">
        <label htmlFor="firstName">First Name:</label>
        <input  {...register("firstName", { required: "This is required." })}
        id="firstName" />
        
        <label htmlFor="lastName">Last Name:</label>
        <input  {...register("lastName", { required: "This is required." })}
        id="lastName" />
         <label htmlFor="email">Email:</label>
        <input  {...register("email", { required: "This is required." })}
        id="email" />
         <label htmlFor="message">Message:</label>
        <input  {...register("message", { required: "This is required." })}
        id="message" />
         <input className="subMit"type="submit" />
        
    </form>
    <figcaption className="pexel-1"> &#169; &nbsp;www.pexels.com</figcaption>
    </div>
    )
}
