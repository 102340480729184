import './App.css';
import Navbar from './components/Navbar'
import Videos from './components/Videos'

import Work from './components/Work'
import Contact from './components/Contact'
import Footer from './components/Footer'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import   ScrollToTop from './components/ScrollToTop'



function App() {
  return (

    <Router >  
    
      <Navbar/>
       <Switch>
      <ScrollToTop>
     
          <Route path='/' exact/>
          <Videos> 
   
          </Videos>
         
          <Route path='/work'/> 
          <Work/>
           <Route path='/contact'/> 
           <Contact/>
 </ScrollToTop>
     </Switch>
  <Footer/>

   </Router>
   
  );
}

export default App;
