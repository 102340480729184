import React from 'react'
import './Work.css'
import ScrollToTop from './ScrollToTop'

const Work = (props) => {
    return (
<section  className="work-container">
    <ScrollToTop>
         <article  className="card">
          <i className="fas  fa-chalkboard icon"></i>
          <article className="card-text">
            <h3> Courses</h3>
            <p className="pOne">A  list of online courses. Some I have completed and others are ongoing
            &nbsp;<i className="fas fa-code"> &nbsp;
            </i>FrauenLoop Web Development Courses <br />
            <i className="fas fa-code"> &nbsp;
                </i>Udemy Web Development Courses.
            </p>
            <button className="btn-color btn one"
             onClick={()=> window.scrollTo(0, 0)}>courses<i className="fa fa-arrow-right" aria-hidden="true"></i></button>
            </article>
            </article>   
           
         
            <article  className="card">
            <i className="fas fa-tasks icon"></i>
            <article className="card-text">
            <h3>Projects</h3>
            <p>Links to various class project from my Udemy classes and FrauenLoop Berlin. &nbsp;The courses will highlight my HTML, CSS, Javascript & React practice. 
            </p>
            <button className="btn-color btn two"
             onClick={()=> window.scrollTo(0, 0)}
            >projects<i className="fa fa-arrow-right" aria-hidden="true"></i></button>
            </article>
           </article> 
          
            
         <article  className="card">
            <i className="fas fa-play icon"></i>
            <article className="card-text">
            <h3>Portfolio</h3>
            <p>Here I will showcase my personal projects which will be found at the link below.
              I will also provide a list of resources on film, television and digital media. 
             
            </p>
             <button className="btn-color btn three"
             onClick={()=> window.scrollTo(0, 0)}
             >Portfolio
            <i className="fa fa-arrow-right" aria-hidden="true"></i></button>
            </article>
           </article>
         </ScrollToTop>              
 </section>
 
    )
}

export default Work
