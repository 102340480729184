import React from 'react';
import './Footer.css';

// import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
     
      <h4>&#169; &nbsp;www.no-ku.com &nbsp; 2021 </h4>
      

     
          </div>
      
     
   
  );
}

export default Footer;
