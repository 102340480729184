import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../assets/noku-logo.png';
import './Navbar.css'
// import {useState, useEffect} from "react"
// import {animateScroll as scroll} from 'react-scroll'


const Navbar = () => {
  // const [scrollNav, setScrollNav] = useState(false)
  // const ChangeNav = () => {
  //   if (window.screenY >= 80) {
  //     setScrollNav(true)
  //   } else  {
  //     setScrollNav(false)
  //   } }
  //   useEffect(() => {
  //     window.addEventListener('scroll', ChangeNav)
  //   }, []);
  //   const toggleHome = () => {
  //     scroll.ScrollToTop();
  //   }

  return (
  <div className="nav-bar bar mui-sticky navBar"> 
  <nav>
   {/* <nav scrollNav={scrollNav}> */}
    <img  src={logo} className="logo2" alt="logo2"  width="100" height="100"/>
        <ul className="nav-links ">
          <li> 
              <Link to ="/"  className="nav-item b-line" 
              // onClick={toggleHome}
           
              >home </Link> </li>  
            <li>   
              <Link to ="/work"  className="nav-item b-line">work</Link> </li>  
            <li> 
               <Link to ="/contact"  className="nav-item b-line">contact </Link> </li>      
             </ul>
             </nav>
            </div>
      
    )
}

export default Navbar
